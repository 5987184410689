<template>
  <div class="container pt-5 mt-5">
    <b-button primary @click="$router.push('/admin/qr')" >Validar QR</b-button>
    <b-button primary @click="$router.push('/admin/list-entries')">Ver listas</b-button>
  </div>
</template>

<script>
export default {
}
</script>

<style>

</style>